import ListEngineUtil,{IListEngineDataObj} from "@/components/base_comp/listEngine/listEngineUtil";
import {watch, reactive, toRefs, onMounted, onBeforeMount, computed, getCurrentInstance, provide, nextTick, defineComponent, inject} from 'vue'
import Condition from './utils/Condition.vue';
import {useRoute} from "vue-router";

const listEngine = defineComponent({
    name: "ListEngine",
    beforeRouteEnter(to, from, next) {
        next(async (engineInst:any)=>{
            if(engineInst.listParam.isBuildPageFromBack){
            }
        })
    },
    props:{
        gridLoaded:{type: Function, default: async (options:any) => {}},
        formatPageInfo:{type: Function, default: async (options:any) => {return options}},
        beforeDelete:{type: Function, default: async (options:any) => {return true}},
        afterDelete:{type: Function, default: (options:any) => {}},
        setDelNoteMsg:{type: Function, default: async(options:any) => {return ''}},
    },
    setup(props,context){
        const instance = getCurrentInstance() as any;
        let {proxy}=instance;
        const utils=proxy.utils;
        const route = useRoute()
        let parentInst=instance.parent.proxy as any;
        provide('gridTableOwner', parentInst);
        let mainComp = inject('mainComp') as any;
        const dataObj:IListEngineDataObj=reactive<IListEngineDataObj>({
            utilInst: {} as any,
            refMap:new Map(),
            mainComp:mainComp,
            fullscreenLoading:true,
            exportType:0,
            exportExcelVisible: false,
            backData: {} as any,

            collapseTitle:'',
            queryCondition:['queryCondition'],
            queryHeight:utils.Const.listEngineCollapseHeight,
            queryConditionHeight: 0,

            listButtons: [],

            listParam: {queryParam:{},idField:"F_ID",isShowFixCol: true,isShowBtnGroup: true, isShowQueryParam: true,autoLoad:false,expandQuery:true,isBuildPageFromBack:true,columnList:[]},
            otherParams:{
                componentLoadFlag:false,
                querySlots:[],
                baseCalHeight:window.innerHeight,
                conditions:[]
            }
        })

        onBeforeMount(()=>{
            dataObj.utilInst=new ListEngineUtil(proxy,dataObj,props,context);
            dataObj.utilInst.initListEngineParams();
        })
        onMounted(async ()=>{
            await doOnMounted();
            window.onresize = ()=> {
                dataObj.otherParams.baseCalHeight=window.innerHeight;
                if(window.innerHeight){
                    dataObj.utilInst.setGridTableContentMaxHeight(dataObj.otherParams.baseCalHeight);
                }
            }
        })
        const doOnMounted=async ()=>{
            await nextTick(async()=>{
                dataObj.otherParams.componentLoadFlag=true;
                await dataObj.utilInst.loadPageData();
                if(dataObj.listParam.expandQuery){
                    dataObj.queryHeight=dataObj.queryConditionHeight+utils.Const.listEngineCollapseHeight;
                    dataObj.collapseTitle=proxy.$t('listEngine.queryOpenTitle');
                }else{
                    dataObj.collapseTitle=proxy.$t('listEngine.queryFoldTitle');
                }
                dataObj.utilInst.setGridTableContentMaxHeight(dataObj.otherParams.baseCalHeight);

            })
        }

        watch(() => {
            if(mainComp)return mainComp.otherParams.showTop;
            else return null;
        },async (newValue,oldValue) => {
            dataObj.utilInst.setGridTableContentMaxHeight(dataObj.otherParams.baseCalHeight);
        })
        const isShowDel=computed(()=>{
            return (row:any) => {
                let isShow=dataObj.backData.canDel;
                let canOperate=true;
                if(row['canOperate']!=undefined)canOperate=row['canOperate'];
                if(context.attrs['isShowDel'])isShow=(context.attrs['isShowDel'] as Function)(row);
                return isShow && canOperate;
            };
        })
        const isShowEdit=computed(()=>{
            return (row:any) => {
                let isShow=dataObj.backData.canEdit || dataObj.backData.canView;
                if(context.attrs['isShowEdit'])isShow=(context.attrs['isShowEdit'] as Function)(row);
                return isShow;
            };
        })

        const clickHandler=(ev:any)=>{
            if(dataObj.utilInst.callEvent(ev,null))return;
            proxy[ev]();
        }
        const queryHandler=(isNewQuery?:any)=>{
            if(isNewQuery==undefined)isNewQuery=true;
            dataObj.refMap.get('tbRef').queryHandler(isNewQuery);
        }
        const addHandler=async ()=>{
            await dataObj.utilInst.showCard({addOrEdit: 'add', id: ''});
        }
        const editHandler=async (row:any)=>{
            if(dataObj.utilInst.callEvent('editHandler',{addOrEdit: 'edit', id: row[dataObj.listParam.idField]}))return;
            await dataObj.utilInst.showCard({addOrEdit: 'edit', id: row[dataObj.listParam.idField]});
        }
        const deleteHandler=async (row:any)=>{
            if(dataObj.utilInst.callEvent('deleteHandler',row))return;
            let delNoteMsg=await props.setDelNoteMsg(row);
            utils.Tools.configBox({
                message:delNoteMsg?delNoteMsg:proxy.$t('deleteNote'),
                sureFn:async ()=> {
                    await dataObj.utilInst.deleteData(row);
                }
            });
        }
        const sureHandler=async (modelEngineRef:any)=>{
            if (parentInst['sureHandler']) {
                return await parentInst['sureHandler'](modelEngineRef);
            }else{
                return true;
            }
        }
        const cancelHandler=async (modelEngineRef:any)=>{
            if (parentInst['cancelHandler']) {
                return await parentInst['cancelHandler'](modelEngineRef);
            }else{
                return true;
            }
        }

        const exportExcelHandler=()=>{
            dataObj.exportExcelVisible = true;
        }
        const sureExport=async()=>{
            await dataObj.utilInst.sureExport();
        }


        const setTbData=(tbData:any)=>{
            dataObj.refMap.get('tbRef').setTbData(tbData);
        }
        const getTbData=()=> {
            return dataObj.refMap.get('tbRef').getTbData();
        }
        const getTbInst=()=>{
            return dataObj.refMap.get('tbRef');
        }


        const setBtnEnable=(val:boolean,id:string)=>{
            if(!id)id='add';//默认是新增按钮
            let btn:any=dataObj.listButtons.find((btn:any) => btn.id==id);
            if(btn)btn.disabled=val;
        }
        return{
            ...toRefs(dataObj),proxy,utils,isShowDel,isShowEdit,queryHandler,addHandler,route,
            sureHandler,cancelHandler,clickHandler,editHandler,deleteHandler,sureExport,exportExcelHandler,
            setTbData,getTbData,getTbInst,setBtnEnable,doOnMounted
        }
    },
    components:{Condition}
});
export default listEngine;